.vertical-timeline::before {
  background: var(--purple);
}

.vertical-timeline-element--i {
  font-family: var(--hello);

  .vertical-timeline-element-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 43%;

    @media only screen and (max-width: 1169px) {
      flex-direction: column;
      justify-content: end;
      padding: 0.5rem;
      width: inherit;
    }
  }

  .vertical-timeline-element-date {
    font-size: 1.5rem !important;
    margin: 0 1.2rem;
    padding: 0 !important;

    @media only screen and (max-width: 1169px) {
      font-size: 1rem !important;
    }
  }

  img {
    margin-right: 0.5rem;
    width: 25%;

    @media (max-width:1169px) and (min-width:769px) {
      width: 10%;
    }

    @media (max-width:768px) and (min-width:426px) {
      width: 15%;
    }
  }

  h4 {
    margin: 0;
  }

  .vertical-timeline-element-title {
    @media only screen and (max-width: 1169px) {
      text-align: left !important;
    }
  }
}