.itinerary-section {
  background: url('../../assets/img//bg/leaf-bg.png') no-repeat center bottom;
  background-size: 120%;
  padding: 1rem 0 8rem;

  @media (max-width: 1024px) {
    background-size: 100%;
    padding-bottom: 5rem;
  }

  @media (max-width: 768px) {
    background-size: 160%;
    padding: 0 0 5rem;
  }

  @media (max-width: 425px) {
    background-size: 250%;
    font-size: 1rem;
    padding-bottom: 4rem;
  }

  .img-wrapper {
    align-items: end;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1169px) {
      .img-section {
        margin-top: 1.5rem;
      }
    }
  }
}