.address-section {
  padding: 4rem 0 2rem;

  @media (max-width: 768px) {
    padding: 2rem 0 1rem;
  }

  img {
    @media (max-width: 768px) {
      margin-bottom: 1.5rem;
    }
  }

  .owl {
    background: url('../../assets/img/owl.png') var(--white) no-repeat center bottom;
    background-size: 20%;

    @media (max-width: 768px) {
      padding-bottom: 8rem;
    }

    @media (max-width: 425px) {
      background-size: 40%;
      padding-bottom: 9rem;
    }
  }
}
