.intro-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  position: absolute;
  text-align: center;
  top: calc(50% - 40px);
  transform: translate(-50%, -50%) !important;

  .animation:global {
    animation-name: hero-default;
    animation-duration: 2s;
    animation-timing-function: steps(20);
    animation-iteration-count: infinite;
    background: url('../../assets/sprites/play-spritesheet-default.png');
    background-repeat: no-repeat;
    height: 500px;
    position: absolute;
    width: 500px;
    transform: translate(-50%, -50%) scale(0.8);
    transform-origin: bottom;
    top: 70%;
    left: 50%;
  }

  img {
    width: 90%;
  }

  button {
    font-size: 1.5rem;
    padding: 0.6rem 0;
    width: 250px;
  }

  @media (max-width: 1024px) {
    .animation {
      top: 68%;
    }

    img {
      width: 160%;
    }

    button {
      margin-top: 4rem !important;
    }
  }

  @media (max-width: 425px) {
    .animation {
      top: 48%;
      transform: translate(-50%, -50%) scale(0.6);
    }

    img {
      width: 150%;
    }

    button {
      margin-top: 5rem !important;
    }
  }

  @media (max-width: 320px) {
    img {
      width: 130%;
    }
  }
}

