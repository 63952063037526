.grid {
  &-1x2 {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    &--dou {
      @media only screen and (max-width: 1169px) {
        gap: 0;
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }

    @media (max-width:768px) {
      gap: 0;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &-1x2-1169 {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media only screen and (max-width: 1169px) {
      gap: 0;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}