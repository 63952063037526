:global {
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
        opacity: 1;
    }
  }

  @keyframes background_move {
    0% {
      background-position: 0 0;
    }

    100% {
        background-position: 0 -1402px;
    }
  }

  @keyframes hero-default {
    100% {
        background-position-x: -10000px
    }
  }

  @keyframes pulse {
    0% { box-shadow: 0 0 0 0 var(--hover); }
  }
}
