.atc-btn {
  position: relative;
  touch-action: manipulation;
  transform: translate3d(0, 0, -12px);

  button.active {
    transform: perspective(100px) rotateX(12deg) translate3d(0, 0, 2px);
    transition: transform .1s linear;
  }

  .links-wrapper {
    background-color: var(--white);
    border-radius: 0 0 0.5rem 0.5rem;
    box-shadow: 0px 2px 4px rgba(81, 92, 98, 0.08), 0px 0px 1px rgba(179, 183, 186, 0.8);
    display: block;
    list-style: none;
    padding: 0;
    position: absolute;
    transform: translate3d(0, 0, 0);
    width: 100%;

    li {
      display: flex;
      cursor: pointer;
      flex-direction: column;
      justify-content: center;
      padding: 0.5rem 1rem;

      &:active,
      &:focus,
      &:hover {
        background-color: var(--perfume);
      }

      a {
        color: var(--dusk);

        &:active,
        &:focus,
        &:hover {
          color: var(--purple);
        }
      }

      svg {
        height: 1rem;
        margin-right: 1rem;
        width: 1rem;
      }
    }
  }
}