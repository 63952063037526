.offcanvas {
  background-image: linear-gradient(to bottom, rgb(165, 213, 253), var(--french-pass));
  color: var(--purple);
  font-size: 1.2rem;

  .offcanvas-header {
    justify-content: space-between;

    h3 {
      font-size: 3.5rem;
    }
  }

  .offcanvas-body {
    display: flex;
    flex-direction: column;
  }
}
