.countdown-section {
  .time {
    display: inline-block;
    margin: 0.7rem;

    > p {
      animation: fade 3s;
      font-size: 3rem;
      line-height: 2.5rem;
      margin: 0;
    }

    > .sp {
      font-size: 1.8rem;
    }

    @media (max-width: 981px) {
      margin: 0.5rem;

      > p {
        font-size: 2rem;
      }

      > .sp {
        font-size: 1.1rem;
      }
    }
  }
}

