.card {
  background-color: var(--white);
  border: 0;
  color: var(--dusk);
  font-family: var(--open-sans);
  font-size: 1rem;
  margin: 1rem 0;
  padding: 1rem;
  position: relative;
  text-align: left;

  h5, u {
    font-family: var(--hello);
    text-decoration: none;
  }

  h5 {
    font-size: 1.5rem;
  }

  u {
    font-size: 1.2rem;
  }
}
