.indications-section {
  padding: 1rem 0 2rem;

  @media (max-width: 768px) {
    padding: 0 0 1rem;
  }

  .ball {
    background: url('../../assets/img/ball.png') var(--white) no-repeat right bottom;
  }

  .bag {
    background: url('../../assets/img/bag.png') var(--white) no-repeat right bottom;
  }

  .ball,
  .bag {
    background-size: 20%;

    @media (max-width: 768px) {
      padding-bottom: 3rem;
    }

    @media (max-width: 425px) {
      background-size: 30%;
    }
  }
}
