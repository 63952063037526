@font-face {
  font-family: 'Hello headline';
  src: url('../../assets/fonts/hello-headline.woff2') format('woff2'),
      url('../../assets/fonts/hello-headline.ttf') format('truetype');
}

@font-face {
  font-family: 'Heyam';
  src: url('../../assets/fonts/heyam.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/open-sans-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/open-sans-bold.woff2') format('woff2');
}

:root {
  --hello: 'Hello headline', sans-serif;
  --heyam: 'Heyam', sans-serif;
  --open-sans: 'Open Sans', sans-serif;
}

.hello {
  font-family: var(--hello);
}

.heyam {
  font-family: var(--heyam);
}

h2 {
  font-size: 3rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 440px) {
    font-size: 2.5rem;
  }
}