:root {
  --animate-duration: 1s;
  --animate-delay: 0.2s;
}

.animate-img {
  animation-delay: var(--animate-delay);
  animation-duration: calc(var(--animate-duration)* 2);
  animation-fill-mode: both;
  animation-name: zoomInDown;
}