.home-section {
  height: 680px;
  padding-top: 6rem;
  position: relative;

  h1 {
    font-size: 10rem;
    line-height: 8rem;
    margin-bottom: 0;
  }

  h3 {
    font-size: 3rem;
  }

  .animation:global {
    animation-name: hero-default;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: steps(20);
    background: url('../../assets/sprites/home-hero-spritesheet-default.png');
    background-repeat: no-repeat;
    bottom: 0;
    height: 500px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0) scale(0.8);
    transform-origin: bottom;
    width: 500px;
  }

  .interaction {
    background: url('../../assets/sprites/home-hero-spritesheet-interaction.png');
  }

  @media (max-width: 981px) {
    height: 455px;
    padding-top: 3rem;

    h1 {
      font-size: 7rem;
    }

    h3 {
      font-size: 2rem;
    }

    .animation {
      transform: translate(-50%, 0) scale(0.5);
    }
  }
}

