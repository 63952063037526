*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

:root {
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
  text-align: center;
  text-size-adjust: none;
  user-select: none;
}

html, body {
  background-color: var(--french-pass);
  background-image: url('../../assets/img/bg/clouds-bg.png');
  background-size: 1865px;
  background-position-y: 672px;
  color: var(--purple);
  font-family: var(--hello);
  font-size: 1rem;
	margin: 0;
  min-height: 100vh;
	padding: 0;
  position: relative;
  text-align: center;

  @media (max-width: 768px) {
    background-image: url('../../assets/img/bg/mobile-clouds-bg.png');
    background-size: 100%;
    background-position-y: 462px;
  }
}

.main {
  height: 100vh;
  width: 100%;
}

.page-wrapper :global {
  animation-name: fadeIn;
  animation-delay: 450ms;
  animation-duration: 350ms;
  animation-fill-mode: both;
}

a {
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    color: var(--dusk);
  }
}

img {
  border-radius: 1rem;
  width: 100%;
}

.title-cloud {
  align-items: end;
  background: url('../../assets/img/clouds/cloud-extra-long.png') no-repeat;
  background-size: contain;
  display: flex;
  height: 124px;
  justify-content: center;

  @media (max-width: 1024px) {
    background: url('../../assets/img/clouds/cloud-long.png') no-repeat;
    background-position: right;
    background-size: cover;
    justify-content: end;
    padding-right: 3rem;
  }

  @media (max-width: 440px) {
    background: url('../../assets/img/clouds/cloud-short.png') no-repeat;
    background-position: right;
    background-size: cover;
    justify-content: center;
    padding-right: 0;
  }
}
