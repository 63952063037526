.confirm-section {
  background: url('../../assets/img/bg/bones.png') var(--wewak);
  color: var(--light-burgundy);
  font-family: var(--open-sans);
  padding: 2rem 0;

  &:active :global,
  &:focus :global,
  &:hover :global {
    animation: background_move .5s steps(3) infinite;
    animation-fill-mode: both;
  }

  button {
    margin: 0 0.2rem;
    width: 30%;

    @media (max-width: 981px) {
      width: 100%;
    }
  }
}

