:root {
  --amazon: #226252;
  --buff: #F1DC86;
  --chinook: #A0E3B6;
  --dusk: #434365;
  --french-pass: #C2E3FE;
  --light-burgundy: #97394E;
  --mule-fawn: #9B5E33;
  --perfume: #DCD2FB;
  --purple: #5A5A87;
  --wewak: #F09AAE;
  --white: #FFFFFF;
}