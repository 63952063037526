.description-section {
  background: url('../../assets/img/bg/paws.png') var(--perfume);
  font-family: var(--open-sans);
  font-size: 1.2rem;
  padding-top: 2rem;

  &:active :global,
  &:focus :global,
  &:hover :global {
    animation: background_move .5s steps(3) infinite;
    animation-fill-mode: both;
  }

  @media (max-width: 425px) {
    font-size: 1rem;
  }

  .bg-toy {
    background: url('../../assets/img/bg/toy-bg.png') no-repeat center bottom;
    background-size: 120%;
    height: 7.3rem;

    @media (max-width: 1024px) {
      background-size: 100%;
      height: 4rem;
    }

    @media (max-width: 768px) {
      background-size: 160%;
      height: 4rem;
    }

    @media (max-width: 425px) {
      background-size: 250%;
      height: 3rem;
    }
  }
}
