button {
  background-color: var(--white);
  border: 0;
  border-radius: 2rem;
  color: var(--purple);
  font-family: var(--hello);
  font-size: 1.2rem;
  outline: 0;
  padding: 0.4rem 0;
  transition: 0.25s;

  &.btn-blue {
    background-color: var(--purple);
    color: var(--white);
  }

  &.btn-red {
    background-color: var(--light-burgundy);
    color: var(--white);
  }

  &.btn-yellow {
    background-color: var(--mule-fawn);
    color: var(--white);
  }

  img {
    width: 40%;
  }

  i {
    margin-right: 0.5rem;
  }

  &.fill {
    &:active,
    &:hover,
    &:focus {
      box-shadow: inset 0 0 0 2em var(--hover);
    }
  }

  &.pulse {
    &:active,
    &:hover,
    &:focus {
      animation: pulse 1s;
      box-shadow: 0 0 0 2em transparent;
    }
  }
}

$colors: (
  fill: #5A5A87,
  pulse: #5A5A87,
);

@each $button, $color in $colors {
  .#{$button} {
    --color: #{$color};
    --hover: #{adjust-hue($color, 45deg)};
  }
}