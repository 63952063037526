.footer-wrapper {
  background-image: url('../../assets/img/bg/bg-playroom.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 640px;
  justify-content: space-between;
  max-width: 1440px;
  min-height: 130px;
  position: relative;
  width: 100%;

  h2 {
    margin-top: 15%;

    @media (max-width: 768px) {
      margin-top: 25%;
    }

    @media (max-width: 448px) {
      margin-top: 45%;
    }
  }

  .animation:global {
    animation-name: hero-default;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: steps(20);
    background: url('../../assets/sprites/make-spritesheet-default.png');
    background-repeat: no-repeat;
    height: 500px;
    left: 50%;
    position: absolute;
    top: 20%;
    transform: translate(-50%, 0) scale(0.8);
    transform-origin: bottom;
    width: 500px;
  }

  .interaction {
    background: url('../../assets/sprites/make-spritesheet-interaction.png');
  }

  @media (max-width: 981px) {
    height: 438px;

    .animation {
      top: 43%;
      transform: translate(-50%, -50%) scale(0.5);
    }
  }
}